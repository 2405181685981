<template>
  <div>
    <Back title="人脸识别"></Back>
    <div class="sketch">
      <div class="sketch-img"></div>
      <p class="sketch-text">
        正面平视手机、保证光线充足 <br>
        请勿遮挡面部
      </p>
    </div>
    <div class="prompt">
      <div class="prompt-box">
        <div class="prompt-box-text">
          <span class="prompt-box-text-number">1</span>
          <span class="prompt-box-text-content">牢记验证码，点击开始录制</span>
          <span class="prompt-box-text-border"></span>
        </div>
        <div class="prompt-box-text">
          <span class="prompt-box-text-number">2</span>
          <span class="prompt-box-text-content">开启前置摄像头，用普通话朗读数字</span>
          <span class="prompt-box-text-border"></span>
        </div>
        <div class="prompt-box-text">
          <span class="prompt-box-text-number">3</span>
          <span class="prompt-box-text-content">完成录制，等待验证结果</span>
        </div>
      </div>
    </div>
    <div style="margin: 16px; padding-bottom: 40px;">
      <van-button round block type="info" @click="nextStep">
        下一步
      </van-button>
    </div>

    <van-dialog v-model="show" :showConfirmButton="false" closeOnClickOverlay @close="beforeClose">
      <div class="modal-layer">
        <div class="modal-layer-popup-title">请牢记以下验证码</div>
        <div class="modal-layer-popup-content">此验证码将于
          <span style="display: inline-block"><van-count-down style="color: red" ref="CountDown1" :time="time1" format="ss" auto-start @finish="resetPost" /></span>
          秒后过期<br>用普通话朗读数字，视频时长<span>3-8</span>秒最佳</div>
        <div class="modal-layer-popup-number">
          <span v-for="(item,index) in numArr" :key="index">{{item}}</span>
        </div>
        <div style="margin: 16px;">
          <div class="modal-wrapper modal-point" :class="{disabled:disabled}">
            <span class="modal-confirm-btn" v-if="disabled">
              记住了，开始录制(<span style="display: inline-block"><van-count-down ref="CountDown2" auto-start :time="time2" format="ss" style="color: #fff" @finish="clearDisable" /></span>s)
            </span>
            <span class="modal-confirm-btn" v-else>
              记住了，开始录制
            </span>
            <input type="file" accept="video/*" capture="camcorder" class="camera-input" @change="submitVedio($event)">
          </div>
        </div>
      </div>
    </van-dialog>

<!--  失败弹框-->
    <van-dialog v-model="errorShow" :showConfirmButton="false" closeOnClickOverlay @close="beforeClose">
      <div class="modal-layer">
        <div class="modal-layer-popup-title">人脸识别失败</div>
        <div class="modal-layer-popup-content">
          失败原因：{{errorMessage}}
        </div>
        <div style="margin: 16px;">
          <van-button round block type="info" @click="resetFace">
            返回
          </van-button>
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model="successLoad"  :showConfirmButton="false" class="van-dialog-loading">
      <template slot="default">
        <div class="spinner">
          <van-loading type="spinner" />
        </div>
      </template>
    </van-dialog>

  </div>
</template>
<script>
import requestHTAPI from '@/axios/HantangTax'
import Back from '@/components/back/index'

export default {
  name: 'index',
  components: {
    Back
  },
  data () {
    return {
      show: false,
      errorShow: false,
      formData: {
        name: '',
        idCard: '',
        mobile: '',
        merId: ''
      },
      verifyId: '',
      numArr: [],
      disabled: true,
      time1: 60 * 1000,
      time2: 3 * 1000,
      successLoad: false,
      errorMessage: ''
    }
  },
  mounted () {
    this.formData = { ...this.$route.query }
  },
  methods: {
    initBag () {
      this.initLive()
    },
    beforeClose (action, done) {
      this.$refs.CountDown1.pause()
      this.$refs.CountDown2.pause()
    },
    submitVedio (e) {
      this.successLoad = true
      const videoData = new FormData()
      const data = {
        verifyId: this.verifyId,
        file: e.target.files[0],
        merId: this.formData.merId
      }
      for (const key in data) {
        videoData.append(key, data[key])
      }
      requestHTAPI.submitVedio(videoData).then(res => {
        this.successLoad = false
        this.show = false
        if (res.data.code == '0000') {
          sessionStorage.setItem('liveStatus', 'true')
          this.$toast('人脸验证成功')
          this.$router.back(-1)
        } else {
          sessionStorage.setItem('liveStatus', 'false')
          this.$toast(res.data.message)
        }
      }).catch(() => {
        this.$toast({
          message: '请求失败,网络异常',
          closeOnClick: true
        })
      })
    },
    nextStep () {
      this.initBag()
      this.show = true
      this.disabled = true
      this.$nextTick(() => {
        this.$refs.CountDown1.reset()
        this.$refs.CountDown2.reset()
      })
    },
    // 1分钟倒计时
    resetPost () {
      this.initBag()
      this.disabled = true
      this.$nextTick(() => {
        this.$refs.CountDown1.reset()
        this.$refs.CountDown2.reset()
      })
    },
    // 3秒倒计时
    clearDisable () {
      this.disabled = false
    },
    // 请求活体数字
    initLive () {
      const formData = new FormData()
      const data = this.formData
      for (const key in data) {
        formData.append(key, data[key])
      }
      requestHTAPI.initLive(formData).then(res => {
        if (res.data.code === '0000') {
          this.$nextTick(() => {
            this.numArr = res.data.data.aliveRandomNumber.split('')
            this.verifyId = res.data.data.verifyId
          })
        } else {
          this.errorCallBack(res.data.message)
        }
      }).catch(() => {
        this.errorCallBack('请求失败,网络异常')
      })
    },
    errorCallBack (message = '') {
      this.show = false
      this.errorShow = true
      this.errorMessage = message
    },
    resetFace () {
      this.$router.back(-1)
    }
  }
}
</script>
<style scoped lang="less">

.sketch{
  background: hsla(0,0%,88%,.35);
  padding: 20px;
}
.sketch-img{
  width: 100%;
  margin: 0 auto;
  height: 500px;
  background: url("../../assets/person.png") no-repeat center;
  background-size: auto 100%;
}
.sketch-text{
  font-size: 28px;
  color: #999;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
  margin: 10px 0;
}
.prompt{
  box-shadow: 0 -4px 10px 0 #e8e8e8;
  padding-bottom: 20px;
}

.prompt-box{
  padding: 90px 20px 80px 50px;
}
.prompt-box-text{
  margin: 0 auto 20px;
  width: 700px;
  text-align: left;
  line-height: 44px;
}
.prompt-box-text-number{
  border-radius: 200px;
  border: 1px solid #1989fa;
  text-align: center;
  color: #1989fa;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 28px;
  letter-spacing: 0;
  vertical-align: top;
}
.prompt-box-text-content{
  color: #333;
  letter-spacing: 0;
  white-space: nowrap;
  display: inline-block;
  font-size: 36px;
}
.prompt-box-text-border{
  height: 40px;
  margin: 10px 20px 10px 20px;
  width: 0;
  border-left: 1px dotted #1989fa;
  display: block;
}

.modal-layer-popup-title{
  font-size: 32px;
  color: #333;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 80px 0 20px;
}
.modal-layer-popup-content{
  font-size: 24px;
  color: #666;
  text-align: center;
  margin-bottom: 50px;
  letter-spacing: 0;
}
.modal-layer-popup-content span{
  color: red;
}
.van-dialog{
  border-radius:10px;
  -webkit-font-smoothing: antialiased;
}
.modal-layer-popup-number span{
  font-size: 60px;
  color: #333;
  display: inline-block;
  width: 100px;
  height: 120px;
  line-height: 120px;
  margin-right: 10px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  text-align: center;
  box-sizing: border-box;
}
.modal-layer-popup-number{
  font-size: 0;
  text-align: center;
  margin-bottom: 80px;
}

.modal-wrapper.modal-point{
  position: relative;
  background: #1989fa;
  height: 80px;
  line-height: 80px;
  border-radius: 50px;
  display: flex;
}

.modal-confirm-btn{
  text-align: center;
  font-size: 24px;
  color: #fff;
  width: 100%;
  height: 100%;
}

.camera-input{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.modal-wrapper.disabled{
  background-color: #ccc
}
.van-dialog-loading{
  background: none;
  .spinner {
    display: flex;
    justify-content: center;
  }
}
</style>
